var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "section",
      {
        staticClass: "section",
        class: { "h-mt-160 section--flexCenter": _vm.beats.length === 0 },
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : _c(
              "div",
              { staticClass: "container container--fullWidth wrapper--filled" },
              [
                _c("table", [
                  _vm._m(1),
                  _c(
                    "tbody",
                    _vm._l(_vm.beats, function (beat, index) {
                      return _c("BeatTableItem", {
                        key: beat.id,
                        attrs: { index: index, beatPlaylist: _vm.beats },
                      })
                    }),
                    1
                  ),
                ]),
                _c("pagination", {
                  attrs: {
                    "page-index": _vm.pageIndex,
                    numberOfPages: _vm.numberOfPages,
                  },
                  on: {
                    changePage: _vm.fetchNewBeats,
                    fetchAll: function ($event) {
                      return _vm.fetchBeats(null)
                    },
                  },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("New ")]),
          _c("h1", [_vm._v("Uploads")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "beat-table" }, [
        _c("th", { attrs: { width: "10" } }),
        _c("th", { staticClass: "beat-table__image", attrs: { width: "38" } }),
        _c("th", { attrs: { width: "10" } }),
        _c("th", [_vm._v("BEAT NAME")]),
        _c("th", { attrs: { width: "240" } }, [_vm._v("PRODUCER")]),
        _c("th", { staticClass: "beat-table__toggle" }, [_vm._v("SHOW")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }