var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "beat-table", class: _vm.audioClasses }, [
    _c("td"),
    _c("td", { staticClass: "table-cell table-cell__play-controls" }, [
      _c("img", {
        staticClass: "table-cell__image",
        attrs: {
          src: _vm.beat.image_url ? _vm.beat.image_url : "./img/picture.svg",
        },
        on: { click: _vm.togglePlayStatus },
      }),
      _c(
        "a",
        { on: { click: _vm.togglePlayStatus } },
        [
          _c("icon", { staticClass: "icon-play", attrs: { name: "play" } }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-pause",
            attrs: { name: "pause" },
          }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAudioPaused && _vm.beat === _vm.beatPlaying,
                expression: "!isAudioPaused && beat === beatPlaying",
              },
            ],
            staticClass: "icon-speaker",
            attrs: { name: "speaker" },
          }),
        ],
        1
      ),
    ]),
    _c("td"),
    _c(
      "td",
      { staticClass: "beat-table__title", on: { click: _vm.togglePlayStatus } },
      [_vm._v(_vm._s(_vm.beat.name))]
    ),
    _c("td", [_vm._v(_vm._s(_vm.beat.producer.display_name))]),
    _c(
      "td",
      { staticClass: "beat-table__toggle" },
      [
        _c("toggle-button", {
          attrs: { color: "#0269FF", labels: true },
          model: {
            value: _vm.showHideInMarketplace,
            callback: function ($$v) {
              _vm.showHideInMarketplace = $$v
            },
            expression: "showHideInMarketplace",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }